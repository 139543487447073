import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cadastro-page',
  templateUrl: './cadastro-page.component.html',
  styleUrls: ['./cadastro-page.component.css']
})
export class CadastroPageComponent implements OnInit {

  spinnerShow = false;

  firstName = "";
  lastName = "";
  address1 = "";
  address2 = "";
  phone = "";
  townCity = "";
  postcode = "";
  email = "";
  password = "";

  termsAndConditions = false;

  urlBase = "https://phaseapp.uk/site/api_site/";

  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  doCadastro() {

    if (this.termsAndConditions) {


      this.spinnerShow = true;

      let firstName = this.firstName.toUpperCase();
      let lastName = this.lastName.toUpperCase();
      let address1 = this.address1.toUpperCase();
      let address2 = this.address2.toUpperCase();
      let townCity = this.townCity.toUpperCase();

      this.http.get(this.urlBase + "apiCadastrar.php?firstName=" + firstName + "&lastName=" + lastName + "&address1=" + address1 + "&address2=" + address2 + "&phone=" + this.phone + "&towCity=" + townCity + "&postcode=" + this.postcode + "&email=" + this.email + "&password=" + this.password).subscribe(
        data => {
          let retorno = data as any;
          if (retorno.success) {
            this.spinnerShow = false;
            this._snackBar.open('Registration successful! Log in to continue!', 'Ok', { duration: 3000 });
            this.router.navigate(['/']);
          }
          else {
            this.spinnerShow = false;
            this._snackBar.open('You already have an account!', 'Ok', { duration: 3000 });
          }
        },
        error => {
          console.log(error);
          this.spinnerShow = false;
        }
      );
    }

    else {
      this._snackBar.open('You need to read and accept the terms and conditions!', 'Ok', { duration: 3000 });
    }
  }

}
