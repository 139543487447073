import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  spinnerShow = false;
  cart = {order: null, services: new Array<any>()};
  quantity = 0;

  currentPage: number = 1;

  categorias = null;
  imagensCarousel = null;

  urlBase = "https://phaseapp.uk/site/api_site/";
  urlImagens = "https://phaseapp.uk/site/imagens/";
  produtos;

  servicesid=[59,60,42,53,43,54,44,45,48,40,38,41];
  public services;
 
  fieldPesquisa = "";
  fieldCategoria = "";

  bannerApp = "";

  numeroWhatsapp = '4407716395636';

  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.carregaDados();
    this.loadCart();
  }

  loadCart(){
    this.cart = JSON.parse(localStorage.getItem("phaseAppCart"));
    if(!this.cart || !this.cart.services){
      this.cart = {order: null, services: new Array<any>()};
    }
  }

  goToSingle(produtoid) {
    this.router.navigate(['/single'], { queryParams: { id: produtoid} });
  }

  carregaCategorias() {
    this.http.get(this.urlBase + "getCategorias.php").subscribe(
      data => {
        this.categorias = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  carregaDados() {
    this.spinnerShow = true;
    this.updateCart();

    this.http.get(this.urlBase + "getProdutos.php").subscribe(
      data => {
        this.produtos = data;
        this.carregaCategorias();
        this.carregaImagens();
        this.loadServices();
        this.spinnerShow = false;
      },
      error => {
        console.log(error);
        this.spinnerShow = false;
      }
    );

    this.http.get(this.urlBase + "getConfiguracoes.php").subscribe(
      data => {
        let retorno = data as any;
        this.bannerApp = retorno[0].CONFIG_IMG_BANNER;
      },
      err => {
        console.log(err);
      }
    );
  }

  loadServices(){
    this.spinnerShow = true;
    let ids=JSON.stringify(this.servicesid);
    this.http.get(this.urlBase + "getServices.php?ids="+ids).subscribe(
      (data) => {
        this.services=data;
      },
      error => {
        console.log(error);
        this.spinnerShow = false;
      }
    );

    this.http.get(this.urlBase + "getConfiguracoes.php").subscribe(
      data => {
        let retorno = data as any;
        this.bannerApp = retorno[0].CONFIG_IMG_BANNER;
      },
      err => {
        console.log(err);
      }
    );
  }

  openPlayStore() {
    window.open("https://play.google.com/store/apps/details?id=uk.co.phaseapp.app", "_blank");
  }

  openAppStore() {
    window.open("https://apps.apple.com/us/app/id1530706947", "_blank");
  }

  carregaImagens() {
    this.http.get(this.urlBase + "getImagensCarousel.php").subscribe(
      data => {
        this.imagensCarousel = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  updateCart() {
    if (this.cart.services) {
      this.quantity = this.cart.services.length;
    }else{
      this.quantity = 0;
    }
  }

  getProductByID(id:number){
    let p=null;
    this.produtos.forEach(produto => {
      if(produto.idprodutos== id){
        p=produto;
      }
    });
     return p;
  }

  addItemCart(id:number) {
    if(!this.cart.services){
      this.cart.services=new Array<any>();
    }
    let found=false;
    let length=this.cart.services.length;
    let i=0;
    while(i<length && !found){
      if(this.cart.services[i].ID==id){
        found=true;
        this.cart.services[i].quantity++;
      }
      i++;
    }
    if(!found){
      let service={ID:id,quantity:1};
      this.cart.services.push(service);
    }
    console.table(this.cart);
    localStorage.setItem("phaseAppCart", JSON.stringify(this.cart));
    console.table(JSON.parse(localStorage.getItem("phaseAppCart")));
    this._snackBar.open('Added to cart!', 'Ok', { duration: 3000 });
    this.updateCart();
  }

  buscarFiltro() {
    if (this.fieldPesquisa || this.fieldCategoria) {
      this.router.navigate(['/search'], { queryParams: { cat: this.fieldCategoria, field: this.fieldPesquisa } });
    }
    else {
      this._snackBar.open('You need to fill in one of the fields for the search!', 'Ok', { duration: 3000 });
    }
  }

  whatsapp() {
    window.open("whatsapp://send?phone=" + this.numeroWhatsapp, '_system', 'location=yes');
  }

}
