<div id="spinner" *ngIf="spinnerShow">
    <mat-spinner></mat-spinner>
</div>
<div class="conteudo-site">
    <app-menu-topo></app-menu-topo>
    <app-menu-principal></app-menu-principal>  
    <div class="faixa-azul">
        <h5>Home > Register Engineer</h5>
    </div>
    <div class="container">
        <div class="row">
            <div class="content-login-form">
                <div class="col-md-12">
                    <form class="login-form" #formCadastro="ngForm" novalidate>
                        <mat-form-field>
                            <mat-label>FIRST NAME</mat-label>
                            <input matInput #campoFirstName="ngModel" type="text" name="firstName"
                                [(ngModel)]="firstName" ngModel required minlength="2" class="text-uppercase">
                        </mat-form-field>
                        <span class="error-input"
                            *ngIf="!campoFirstName.valid && (campoFirstName.dirty || campoFirstName.touched)">Required!</span>

                        <mat-form-field>
                            <mat-label>LAST NAME</mat-label>
                            <input matInput #campoLastName="ngModel" type="text" name="lastName" [(ngModel)]="lastName"
                                ngModel required minlength="2" class="text-uppercase">
                        </mat-form-field>
                        <span class="error-input"
                            *ngIf="!campoLastName.valid && (campoLastName.dirty || campoLastName.touched)">Required!</span>

                        <mat-form-field>
                            <mat-label>ADDRESS LINE 1</mat-label>
                            <input matInput #campoAddress1="ngModel" type="text" name="address1" [(ngModel)]="address1"
                                ngModel required minlength="5" class="text-uppercase">
                        </mat-form-field>
                        <span class="error-input"
                            *ngIf="!campoAddress1.valid && (campoAddress1.dirty || campoAddress1.touched)">Required!</span>

                        <mat-form-field>
                            <mat-label>ADDRESS LINE 2</mat-label>
                            <input matInput #campoAddress2="ngModel" type="text" name="address2" [(ngModel)]="address2"
                                ngModel class="text-uppercase">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>PHONE</mat-label>
                            <input matInput #campoPhone="ngModel" type="tel" name="phone" [(ngModel)]="phone" ngModel
                                required minlength="4">
                        </mat-form-field>
                        <span class="error-input"
                            *ngIf="!campoPhone.valid && (campoPhone.dirty || campoPhone.touched)">Required!</span>

                        <mat-form-field>
                            <mat-label>TOWN OR CITY</mat-label>
                            <input matInput #campoTownCity="ngModel" type="text" name="townCity" [(ngModel)]="townCity"
                                ngModel required minlength="3" class="text-uppercase">
                        </mat-form-field>
                        <span class="error-input"
                            *ngIf="!campoTownCity.valid && (campoTownCity.dirty || campoTownCity.touched)">Required!</span>

                        <mat-form-field>
                            <mat-label>POSTCODE</mat-label>
                            <input matInput #campoPostcode="ngModel" type="text" name="postcode" [(ngModel)]="postcode"
                                ngModel required minlength="3">
                        </mat-form-field>
                        <span class="error-input"
                            *ngIf="!campoPostcode.valid && (campoPostcode.dirty || campoPostcode.touched)">Required!</span>

                        <mat-form-field>
                            <mat-label>E-MAIL ADDRESS</mat-label>
                            <input matInput #campoEmail="ngModel" type="email" name="email" [(ngModel)]="email" ngModel
                                required minlength="5">
                        </mat-form-field>
                        <span class="error-input"
                            *ngIf="!campoEmail.valid && (campoEmail.dirty || campoEmail.touched)">Required!</span>

                        <mat-form-field>
                            <mat-label>CREATE PASSWORD</mat-label>
                            <input matInput #campoPassword="ngModel" type="password" name="password"
                                [(ngModel)]="password" ngModel required minlength="3">
                        </mat-form-field>
                        <span class="error-input"
                            *ngIf="!campoPassword.valid && (campoPassword.dirty || campoPassword.touched)">Required!</span>
                        
                        <input type="file"
                            multiple="multiple" (change)="onFileChange($event)"
                         id="file">
                        <mat-checkbox class="checkbox-cadastro" class="example-margin" name="termsAndConditions"
                            [(ngModel)]="termsAndConditions" data-toggle="modal" data-target="#modalTerms" required>I
                            read and accept the Terms and Conditions
                        </mat-checkbox>
                    </form>
                    <button mat-raised-button (click)="doCadastro()" color="primary" class="btn-form"
                        style="margin-bottom: 30px !important;" [disabled]="!formCadastro.valid">SIGN UP</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="modalTerms" tabindex="-1" role="dialog" aria-labelledby="modalTermsLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalTermsLabel">Terms and conditions:</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p style="white-space: pre-line; color: #001d46;">
                    “PhaseApp” is a trading name of Phase App Ltd. Registered as a company in England and Wales no.
                    12449341. Registered
                    office: 5,Jardine House- Bessborough Road, Harrow-on the Hill, London HA1 3EX.
                    <br><br>

                    <strong>• Acceptance of Terms</strong><br><br>
                    • Your access to and use of PhaseApp ("the App/Website") and the Services (defined below) outlined
                    in Clause 2, are
                    subject exclusively to these Terms and Conditions.
                    <br><br>
                    • You agree not to use the App/Website and/or Services for any purpose that is unlawful or
                    prohibited by these Terms
                    and Conditions.
                    <br><br>
                    • By using the App/Website and/or Services you are fully accepting the Terms and Conditions. If you
                    do not accept
                    these Terms and Conditions you must immediately stop using the App/Website and/or Services.
                    <br><br>
                    • PhaseApp reserves the right to update or amend these Terms and Conditions at any time and your
                    continued use of
                    the App/website and/or Services following any changes shall be deemed to be your acceptance of such
                    change. It is
                    therefore your responsibility to check the Terms and Conditions regularly for any changes.
                    <br><br>
                    • PhaseApp may update the App/Website from time to time, and may change the content at any time.
                    <br><br>
                    • These Terms and conditions were last updated on 17January 2020.<br><br>


                    <strong>2.The Services</strong>
                    <br><br>
                    • PhaseApp takes steps to verify the credentials and company status of its members (the “Vetting
                    Process”).
                    <br><br>
                    • PhaseApp collects and collates feedback from customers of its members by means of feedback
                    (“Monitoring”).
                    <br><br>
                    • PhaseApp collates recommendations from the public in relation to its members and the services they
                    provide
                    (“Recommendations”).
                    <br><br>
                    • The results of the Recommendation, Vetting and Monitoring processes are published on the
                    App/Website.
                    PhaseApp aims to:
                    <br><br>
                    • Improve the accountability and credibility of its members through publishing impartial feedback on
                    their work;
                    <br><br>
                    • Promote quality service, workmanship and best practice among its members; and
                    <br><br>
                    • Promote its members to the public.


                    <strong>3.Other Applicable Terms</strong>
                    <br><br>
                    • These Terms and Conditions refer to the following additional terms, which also apply to your use
                    of the
                    App/Website:
                    <br><br>
                    • PhaseApp’s privacy notice which sets out the terms on which we process any personal data we
                    collect from you or
                    that you provide to PhaseApp (“Privacy Notice”). The Privacy Notice also sets out who we share the
                    content you
                    upload or provide with;
                    <br><br>
                    • If you are a member, PhaseApp’s Code of Conduct and Ethics will apply to the way in which you
                    conduct your
                    business.
                    <br><br>
                    • If you are a member, our Terms and Conditions for members will also apply to your use of the
                    App/Website. In the
                    event of a conflict between these Terms and Conditions and the Terms and Conditions for members, the
                    contents of
                    those additional terms shall prevail.

                    <strong>4.Access to the App/Website</strong>
                    <br><br>
                    • PhaseApp do not guarantee that the App/Website or any content contained on the App/Website will
                    always be
                    available or be uninterrupted.
                    <br><br>
                    • You are responsible for making all arrangements necessary for you to have access to the
                    App/Website.
                    <br><br>
                    • Your access to the App/Website is permitted at all time.
                    <br><br>
                    • PhaseApp may suspend, withdraw, discontinue or change all or any part of the App/Website without
                    notice to you.
                    <br><br>
                    • PhaseApp will not be liable to you for any reason if the App/Website is unavailable at any time or
                    for any period.
                    <br><br>


                    <strong>5.Intellectual Property Rights</strong>
                    <br><br>
                    • The App/Website and its content (including without limitation the App/Website design, text,
                    graphics and all
                    software and source codes connected with the App/Website and the Services) are protected by
                    copyright, trademarks,
                    and other intellectual property rights and laws. In accessing the App/Website you agree that you
                    will access the
                    contents solely for your personal, non-commercial use.
                    <br><br>
                    • None of the content contained on the App/Website may be downloaded, copied, reproduced,
                    transmitted, stored, sold
                    or distributed without the prior written consent of PhaseApp. This excludes the downloading, copying
                    and/or printing
                    of pages of the App/Website for personal, non-commercial use only or for use by PhaseApp members in
                    promoting their
                    business.
                    • When you upload any content (including text or photographs) to the App/Website, you assign all
                    rights in such
                    content to PhaseApp.
                    <br><br>
                    • You agree to PhaseApp using the name you submit in connection with the content you upload.
                    <br><br>
                    • You agree that once you have submitted content, the content will become the property
                    of PhaseApp and you cannot
                    recall any part of such content.
                    <br><br>


                    <strong>6.No reliance on information or speed of service</strong>
                    <br><br>
                    • The content contained on the App/Website is provided for general information only, all images are
                    illustrative and
                    are for guidance only and NOT from the product that will be installed in your property upon the
                    services required.
                    It is not intended to amount to advice on which you should rely.
                    <br><br>
                    • Although PhaseApp makes reasonable efforts to update the information on the App/Website, please
                    note that the
                    content contained on the App/Website may be out of date at any given time and PhaseApp are under no
                    obligation to
                    update it.
                    <br><br>
                    • In case of customers experiencing emergency situations PhaseApp aims to put such customers in
                    touch with our
                    electricians who are able to provide a fast response through its EMERGENCY SERVICES such as Power
                    failure and Fault
                    Finding Services. However, PhaseApp cannot guarantee a specific response time and will not be liable
                    for the failure
                    of any electrician to succeed in providing any service in any specific time quoted.
                    <br><br>

                    <strong>7.Prohibited use</strong>
                    <br><br>
                    • You agree to use the App/Website for lawful purposes only and not:
                    <br>
                    • In any way that breaches any applicable local, national or international law or regulation;
                    <br>
                    • In a way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;
                    <br>
                    • For the purposes of harming or attempting to harm any person, business or entity in anyway;
                    <br>
                    • To undermine the purpose and provision of Services;
                    <br>
                    • To gain a competitive advantage over any businesses in your sector;
                    <br>
                    • To act in competition with the Services;
                    <br>
                    • To violate the privacy of others;
                    <br>
                    • To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional
                    material or any
                    other form of solicitation;
                    <br>
                    • To reverse engineer, decompile, dissemble, decipher or otherwise derive the code used on the
                    App/Website;
                    <br>
                    • To use any robot, spider, scraper, or other automated means to access the App/Website; or
                    <br>
                    • To reproduce, duplicate, copy, sub-licence, broadcast, distribute, sell, re-brand or re-sell any
                    part of the
                    App/Website (without PhaseApp’s express consent).
                    <br><br>


                    <strong>8.Links to third party websites and linking to the Website</strong>
                    <br><br>
                    • The App/Website/Services may include links to third party websites that are controlled and
                    maintained by others.
                    Any link to other websites is not an endorsement of such websites and you acknowledge and agree
                    that PhaseApp has no
                    control over such websites and is not responsible for the content or availability of any such
                    websites.
                    <br>
                    • You may link to the Website’s homepage, provided you do so in a way that is fair and legal and
                    does not damage our
                    reputation or take advantage of it.
                    <br>
                    • You must not establish a link in such a way as to suggest any form of association, approval or
                    endorsement
                    by PhaseApp where none exists.
                    <br>
                    • PhaseApp reserves the right to withdraw linking permission without notice.
                    <br><br>


                    <strong>9.Viruses</strong>
                    <br><br>
                    • PhaseApp cannot guarantee that the App/Website will be secure or free from bugs or viruses.
                    <br>
                    • PhaseApp will not be liable for any loss or damage caused by a virus, or any other technologically
                    harmful
                    material that may infect your computer equipment, data, computer programs or other proprietary
                    material due to your
                    use of the App/Website or to your downloading of any content on the App/Website, or linked to the
                    App/Website,
                    where PhaseApp have taken reasonable security to prevent this.
                    <br><br>


                    <strong>10.International Use</strong>
                    <br><br>
                    You agree to comply with all applicable laws regarding the transmission of technical data exported
                    from the United
                    Kingdom or the country in which you reside (if different) and with all local laws and rules
                    regarding acceptable use
                    of and conduct on the Internet.
                    <br><br>


                    <strong>11.Indemnity</strong>
                    <br><br>
                    • You agree to comply with all applicable laws regarding the transmission of technical data exported
                    from the United
                    Kingdom or the country in which you reside (if different) and with all local laws and rules
                    regarding acceptable use
                    of and conduct on the Internet.
                    <br>
                    • You agree to indemnify and hold PhaseApp and its employees and agents harmless from and against
                    any breach by you
                    of these Terms and Conditions and any claim or demand brought against PhaseApp by any third party
                    arising out of
                    your use of the Services and/or any Content submitted, posted or transmitted through the Services,
                    including without
                    limitation, all claims, actions, proceedings, losses, liabilities, damages, costs, expenses
                    (including reasonable
                    legal costs and expenses) howsoever suffered or incurred by PhaseApp in consequence of your breach
                    of these Terms
                    and Conditions.
                    <br><br>


                    <strong>12.Disclaimers and limitation of liability</strong>
                    <br><br>
                    • Use of the App/Website/Services is at your own risk. The App/Website/Services are provided on an
                    "AS IS" and "AS
                    AVAILABLE" basis without any representation or endorsement made and without warranty of any kind
                    whether express or
                    implied, including but not limited to the implied warranties of satisfactory quality, fitness for a
                    particular
                    purpose, non-infringement, compatibility, security and accuracy.
                    <br>
                    • To the extent permitted by law, PhaseApp will not be liable for any indirect or consequential loss
                    or damage
                    whatsoever (including without limitation loss of business, opportunity, data, profits) arising out
                    of or in
                    connection with the use of the App/Website/Services.
                    <br>
                    • Whilst PhaseApp makes reasonable efforts to ensure that all information it provides is
                    accurate, PhaseApp makes no
                    warranty that the App/Website/Services will meet your requirements, that the Content will be
                    accurate or reliable,
                    that the functionality of the App/Website/Services will be uninterrupted or error free, that defects
                    will be
                    corrected or that the App/Website/Services or the server that makes them available are free of
                    viruses or anything
                    else that may be harmful or destructive.
                    <br>
                    • PhaseApp will not be liable to you for any loss suffered (even if such loss is foreseeable) in
                    relation to your
                    use or inability to use the App/Website, any inaccuracies or delays on the App/Website or any
                    reliance by you on
                    content contained on the App/Website.
                    <br>
                    • Nothing in these Terms and Conditions shall be construed so as to exclude or limit the liability
                    of PhaseApp for
                    death or personal injury as a result of the negligence of PhaseApp or that of its employees or
                    agents/electricians.
                    <br>
                    • Nothing in these Terms and Conditions shall affect your statutory rights as a consumer.
                    Whilst every effort is made to verify statements and information submitted by the public and
                    PhaseApp
                    members/electricians/engineers, the information compiled by PhaseApp is based on information
                    supplied by various
                    individuals and businesses, it does not represent the views of PhaseApp and PhaseApp cannot be held
                    responsible for
                    any omissions or inaccurate claims that may appear.
                    Users of the service do hereby represent, understand and expressly agree that PhaseApp does not make
                    any warranty or
                    guarantee over the quality, timing, integrity, responsibility, legality or any other aspect
                    whatsoever of the
                    materials or services offered or delivered by its members/electricians to their customers.
                    You hereby expressly agree not to hold PhaseApp (or PhaseApp's agents, representatives, advertisers
                    or employees)
                    liable for any instruction, advice or services delivered which originated through the App/website or
                    associated
                    Services and PhaseApp expressly disclaims any liability whatsoever for any damage, suits, claims,
                    and/or
                    controversies that have arisen or may arise, whether known or unknown therefrom.
                    <br><br>

                    <strong>13.Uploading content to the Website</strong>
                    <br><br>
                    • If you are over the age of 18 you are permitted to upload content. Where you upload content to the
                    App/Website you
                    must ensure that all content uploaded:
                    <br>
                    • Is accurate, true, up to date, genuine and not misleading;
                    <br>
                    • Is relevant and appropriate;
                    <br>
                    • Complies with applicable law in the UK and in any country from which the content is posted or
                    viewed and does not
                    promote illegal activity;
                    <br>
                    • Complies with the Privacy Notice and does not disclose any personal or confidential information;
                    <br>
                    • Does not contain material which is or potentially could be defamatory, obscene, aggressive,
                    offensive, hateful,
                    inflammatory or rude;
                    <br>
                    • Does not infringe the copyright or any intellectual property rights of any person and where such
                    content relates
                    to an image, you must ensure that all necessary consents and authorisations have been obtained;
                    <br>
                    • Does not promote discrimination based on race, sex, religion, nationality, disability, sexual
                    orientation or age;
                    <br>
                    • Does not relate to the services provided by PhaseApp;
                    <br>
                    • Does not have the purpose or effect of advertising, discussing or commenting on third party
                    businesses who are not
                    a Member;
                    <br>
                    • Is not threatening, abusive or invades others privacy or causes annoyance, inconvenience or
                    needless anxiety;
                    <br>
                    • Does not impersonate any person or misrepresent identities or affiliations; or
                    <br>
                    • Does not give the impression that it emanates from PhaseApp (if this is not the case).
                    (“Content Standards”)
                    <br><br>

                    13.2 Where content you upload does not comply with the Content Standards or requires
                    clarification, PhaseApp may
                    contact you for further information, before publishing such content.
                    <br>
                    13.3 You warrant that all content you upload shall comply with the Content Standards, and you agree
                    to
                    indemnify PhaseApp for any loss PhaseApp suffers as a result of your failure to comply with the
                    Content Standards.
                    <br>
                    13.4 PhaseApp will not be responsible, or liable to any third party, for the content or accuracy of
                    any content
                    uploaded by you to the Website.
                    <br>
                    13.5PhaseApp reserves the right to remove or withhold any content at any time which it considers
                    fails to adhere
                    with the Content Standards set out in clause 13.1.
                    <br>
                    • PhaseApp reserves the right to edit content you upload to amend spelling, grammar, punctuation,
                    length, for the
                    purposes of clarity and to ensure that the content can comply with the Content Standards.
                    <br><br>


                    • PhaseApp’s rights
                    <br>
                    • PhaseApp will determine at its discretion, whether you have failed to comply with these Terms and
                    Conditions.
                    <br>
                    Failure to comply with these Terms and Conditions constitutes a material breach and may result
                    in PhaseApp:
                    <br><br>
                    • Temporarily or permanently withdraw your access to the App/Website;
                    <br>
                    • Commence legal proceedings against you for your actions;
                    <br>
                    • Disclose necessary information to law enforcement authorities.
                    <br>
                    • Severance
                    If any of these Terms and Conditions should be determined to be invalid, illegal or unenforceable
                    for any reason by
                    any court of competent jurisdiction then such Term or Condition shall be severed and the remaining
                    Terms and
                    Conditions shall survive and remain in full force and effect and continue to be binding and
                    enforceable.
                    <br>
                    • Governing Law
                    These Terms and Conditions shall be governed by and construed in accordance with the law of England
                    and Wales and
                    you hereby submit to the exclusive jurisdiction to the courts of England and Wales.
                    (Revised: 17 January 2020)
                    <br><br>

                </p>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn-padrao-site">Save changes</button>
            </div> -->
        </div>
    </div>
</div>
<app-footer></app-footer>