import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-customer-password',
  templateUrl: './change-customer-password.component.html',
  styleUrls: ['./change-customer-password.component.css']
})
export class ChangeCustomerPasswordComponent implements OnInit {

  urlBase = "https://phaseapp.uk/site/api_site/";

  spinnerShow = false;
  email="";
  password="";
  token="";
  constructor(private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token= params["token"];
      console.log(this.token);
    });
   }

  ngOnInit(): void {
  }

  ChangePassword() {
    this.spinnerShow = true;
    this.http.get(this.urlBase + "changecustomerpassword.php?email="+this.email+"&password="+this.password+"&token="+this.token).subscribe(
      data => {
        console.log("data"+data);
        let retorno = data as any;
        if (retorno.success) {
          this.spinnerShow = false;
          this.router.navigate(['/']);
        }
        else {
          this.spinnerShow = false;
          console.log("error "+retorno.error);
          this._snackBar.open('Error changing the password!', 'Ok', { duration: 3000 });
        }
      },
      error => {
        console.log(error);
        this.spinnerShow = false;
      }
    );
  }

}
