import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor() { }

  qtdProdutos = 0;

  ngOnInit(): void {
    this.updateCart();
  }

  updateCart() {
    let carrinho = JSON.parse(localStorage.getItem("phaseAppCart"));

    if (carrinho) {
      this.qtdProdutos = carrinho.services.length;
    }
  }

}
