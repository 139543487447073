import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';

import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HomePageComponent } from './home-page/home-page.component';
import { CartComponent } from './cart/cart.component';
import { MenuTopoComponent } from './menu-topo/menu-topo.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { LoginPageComponent } from './login-page/login-page.component';
import { CadastroPageComponent } from './cadastro-page/cadastro-page.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { FooterComponent } from './footer/footer.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { FinalizarAgendamentoComponent } from './finalizar-agendamento/finalizar-agendamento.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { MenuPrincipalComponent } from './menu-principal/menu-principal.component';
import { SearchComponent } from './search/search.component';
import { MinhaContaComponent } from './minha-conta/minha-conta.component';
import { DetailPedidoComponent } from './detail-pedido/detail-pedido.component';
import { CadastroEngineerComponent } from './cadastro-engineer/cadastro-engineer.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SingleServiceComponent } from './single-service/single-service.component';
import { GalleryComponent } from './gallery/gallery.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { PagamentoDebitoComponent } from './pagamento-debito/pagamento-debito.component';
import { RecoverCustomerPasswordComponent } from './recover-customer-password/recover-customer-password.component';
import { ChangeCustomerPasswordComponent } from './change-customer-password/change-customer-password.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    CartComponent,
    MenuTopoComponent,
    LoginPageComponent,
    CadastroPageComponent,
    FooterComponent,
    FinalizarAgendamentoComponent,
    AboutComponent,
    ServicesComponent,
    CategoriasComponent,
    MenuPrincipalComponent,
    SearchComponent,
    MinhaContaComponent,
    DetailPedidoComponent,
    CadastroEngineerComponent,
    SingleServiceComponent,
    GalleryComponent,
    PagamentoDebitoComponent,
    RecoverCustomerPasswordComponent,
    ChangeCustomerPasswordComponent
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [
    MatDatepickerModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
