<div id="spinner" *ngIf="spinnerShow">
    <mat-spinner></mat-spinner>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <p style="text-align: center; font-weight: bold;">Total £{{valorTotal.toFixed(2)}}</p>
        </div>
    </div>
</div>
<div id="paypal-checkout-btn"></div>