import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  dadosLoginClientPhaseSite = null;
  firstName = null;

  ngOnInit(): void {
    this.dadosLoginClientPhaseSite = JSON.parse(localStorage.getItem("dadosLoginClientPhaseSite"));
    if (this.dadosLoginClientPhaseSite) {
      let dadosLoginClientPhaseSite = this.dadosLoginClientPhaseSite as any
      this.firstName = dadosLoginClientPhaseSite.CLI_FIRSTNAME;
    }
  }

  verificaLogin() {
    setInterval(() => {
      let dadosLoginClientPhaseSite = localStorage.getItem("dadosLoginClientPhaseSite");
      let jaVerificou = localStorage.getItem("verificouLoginPhaseAppClient");
      if (dadosLoginClientPhaseSite && !jaVerificou) {
        localStorage.setItem("verificouLoginPhaseAppClient", "true");
        window.location.reload();
      }
    }, 100);
  }

  logout() {
    localStorage.removeItem("dadosLoginClientPhaseSite");
    window.location.reload();
  }

}
