<div id="spinner" *ngIf="spinnerShow">
    <mat-spinner></mat-spinner>
</div>
<div class="conteudo-site">
    <app-menu-topo></app-menu-topo>
    <app-menu-principal></app-menu-principal>  
    <div class="faixa-azul">
        <h5>Home > My Account</h5>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <app-categorias></app-categorias>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-12">
                        <div class="dados-cliente" *ngIf="dadosLoginClientPhaseSite">
                            <p><strong>First Name:</strong> {{dadosLoginClientPhaseSite.CLI_FIRSTNAME}}</p>
                            <p><strong>Last Name:</strong> {{dadosLoginClientPhaseSite.CLI_LASTNAME}}</p>
                            <p><strong>Address Line 1:</strong> {{dadosLoginClientPhaseSite.CLI_ADDRESS1}}</p>
                            <p><strong>Address Line 2:</strong> {{dadosLoginClientPhaseSite.CLI_ADDRESS2}}</p>
                            <p><strong>Phone:</strong> {{dadosLoginClientPhaseSite.CLI_PHONE}}</p>
                            <p><strong>Town or City:</strong> {{dadosLoginClientPhaseSite.CLI_TOWNCITY}}</p>
                            <p><strong>Postcode:</strong> {{dadosLoginClientPhaseSite.CLI_POSTCODE}}</p>
                            <p><strong>E-mail:</strong> {{dadosLoginClientPhaseSite.CLI_EMAIL}}</p>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="pedidosFeitos">
                    <div class="col-md-12">
                        <table class="table-pedidos" *ngIf="pedidosFeitos.length">
                            <thead>
                                <tr>
                                    <th colspan="5" class="text-center" style="font-size: 14pt;">ORDERS</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th class="text-center">Scheduled date</th>
                                    <th class="text-center">Amount</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Engineer</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let pedido of pedidosFeitos">
                                    <td>
                                        <button (click)="detailPedido(pedido.idorders)" class="btn-orders"
                                            data-toggle="modal" data-target="#modalDetails">
                                            <span class="material-icons">
                                                visibility
                                            </span>
                                        </button>
                                    </td>
                                    <td class="text-center">{{pedido.ORD_DATE | date: 'dd/MM/yyyy H:mm'}}</td>
                                    <td class="text-center">£{{pedido.ORD_TOTAL}}</td>
                                    <td class="text-center">{{pedido.ORD_STATUS}}</td>
                                    <td class="text-center">{{pedido.ENG_FIRST_NAME}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalDetails" tabindex="-1" role="dialog" aria-labelledby="modalDetailsLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalDetailsLabel">Order items:</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="list-itens-order">
                    <p *ngFor="let pedido of produtosDoPedido">{{pedido.PROD_NOME}} - £{{pedido.PROD_PRECO}}</p>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn-padrao-site">Save changes</button>
            </div> -->
        </div>
    </div>
</div>