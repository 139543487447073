import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css']
})
export class CategoriasComponent implements OnInit {

  urlBase = "https://phaseapp.uk/site/api_site/";

  categorias1: Array<any>;
  catId = null;
  categoriasShow = 4;
  
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.carregarDados();
  }

  carregarDados() {
    this.http.get(this.urlBase + "getCategorias.php").subscribe(
      data => {
        this.categorias1 = data as any;
        this.route.queryParams.subscribe(params => {
          this.catId = params['cat'];
        });
      },
      error => {
        console.log(error);
      }
    );
  }

}
