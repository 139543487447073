<div id="spinner" *ngIf="spinnerShow">
    <mat-spinner></mat-spinner>
</div>
<div class="conteudo-site">
    <app-menu-topo></app-menu-topo>
    <app-menu-principal></app-menu-principal>
    <div class="container">
        <div class="row">
            <div class="col-md-12">

            </div>
        </div>
    </div>
    <div class="fundo-carousel">
        <div>
            <div>
                <div>
                    <div id="carousel-banners" class="carousel slide" data-ride="carousel">
                        <!--<ol class="carousel-indicators">
                            <li data-target="#carousel-banners" data-slide-to="0" class="active"></li>
                        </ol>-->
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div style="background-image:url(assets/imagens/banner1.jpg)" class="imgcarousel"></div>
                                <div>
                                    <div class="row">
                                        <div class="col-md-12 textcarousel divcarousel">
                                            <h2>24 Hours Electricians</h2>
                                            <h3>Rapid Response</h3>
                                            <a href="/search?cat=2" class="button">Start</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--<a class="carousel-control-prev" href="#carousel-banners" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carousel-banners" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row servicesinfo container">
        <div class="col-md-4">
            <img src="assets/imagens/relogio.png" class="icon-info-services">
            <h3 class="title-info-services">Online support 24/7.</h3>
            <p class="text-info-services">Simple and fast. Available via WhatsApp Messenger</p>
        </div>
        <div class="col-md-4">
            <img src="assets/imagens/cofre.png" class="icon-info-services">
            <h3 class="title-info-services">Free cancellation.</h3>
            <p class="text-info-services">You can cancel or change your appointment easily.</p>
        </div>
        <div class="col-md-4">
            <img src="assets/imagens/checkout1.png" class="icon-info-services">
            <h3 class="title-info-services">100% secure checkout.</h3>
            <p class="text-info-services">Shop with confidence with our safe secure SSL certificate and most trusted payment processor in the world, PayPal®.</p>
        </div>
    </div>
    <!--<div class="container">
        <div class="row">
            <div class="col-md-12" *ngIf="bannerApp">
                <div class="banner-app" style="background-image: url(assets/imagens/banner-app.png);">
                    <button (click)="openPlayStore()"><img src="assets/imagens/play-store-icon.png"></button>
                    <button (click)="openAppStore()"><img src="assets/imagens/app-store-icon.png"></button>
                </div>
            </div>
        </div>
    </div>-->
    <div class="container">
        <div class="row">
            <!--<div class="col-md-3">
                <app-categorias></app-categorias>
            </div>-->
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="title-services-home">Emergency services</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id59.img}}');" (click)="goToSingle(59)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(59)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div class="title-item-services name-service-single" (click)="goToSingle(59)">
                                            {{services.id59.name}}
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(59)">
                                            £{{services.id59.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id60.img}}');" (click)="goToSingle(60)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(60)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div class="title-item-services name-service-single" (click)="goToSingle(60)">
                                            {{services.id60.name}}
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(60)">
                                            £{{services.id60.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id42.img}}');" (click)="goToSingle(42)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(42)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div class="title-item-services name-service-single" (click)="goToSingle(42)">
                                            {{services.id42.name}}
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(42)">
                                            £{{services.id42.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="title-services-home">Electrical Certificates</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id53.img}}');" (click)="goToSingle(53)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(53)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div class="title-item-services name-service-single" (click)="goToSingle(53)">
                                            {{services.id53.name}}
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(53)">
                                            £{{services.id53.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id43.img}}');" (click)="goToSingle(43)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(43)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div matTooltip="Electrical Installation Condition Report" class="title-item-services name-service-single" (click)="goToSingle(43)">
                                            EICR
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(43)">
                                            £{{services.id43.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id54.img}}');" (click)="goToSingle(54)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(54)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div class="title-item-services name-service-single" (click)="goToSingle(54)">
                                            {{services.id54.name}}
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(54)">
                                            £{{services.id54.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id44.img}}');" (click)="goToSingle(44)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(44)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div class="title-item-services name-service-single" (click)="goToSingle(44)">
                                            {{services.id44.name}}
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(44)">
                                            £{{services.id44.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id45.img}}');" (click)="goToSingle(45)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(45)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div class="title-item-services name-service-single" (click)="goToSingle(45)">
                                            {{services.id45.name}}
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(45)">
                                            £{{services.id45.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id48.img}}');" (click)="goToSingle(48)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(48)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div class="title-item-services name-service-single" (click)="goToSingle(48)">
                                            {{services.id48.name}}
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(48)">
                                            £{{services.id48.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="title-services-home">Other</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id40.img}}');" (click)="goToSingle(40)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(40)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div class="title-item-services name-service-single" (click)="goToSingle(40)">
                                            {{services.id40.name}}
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(40)">
                                            £{{services.id40.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id38.img}}');" (click)="goToSingle(38)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(38)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div class="title-item-services name-service-single" (click)="goToSingle(38)">
                                            {{services.id38.name}}
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(38)">
                                            £{{services.id38.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="services">
                                <div class="item-und-services">
                                    <div class="icon-item-services img-service-single" style="background-image : url('https://phaseapp.uk/site/imagens/{{services.id41.img}}');" (click)="goToSingle(41)">
                                    </div>
                                    <div class="content-card-item">
                                        <!-- matTooltip="Add to cart" -->
                                        <button class="button-buy-service" (click)="addItemCart(41)">
                                            <svg class="bi bi-bucket-fill icon-comprar" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 1.5A4.5 4.5 0 003.5 6h-1a5.5 5.5 0 1111 0h-1A4.5 4.5 0 008 1.5z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M1.61 5.687A.5.5 0 012 5.5h12a.5.5 0 01.488.608l-1.826 8.217a1.5 1.5 0 01-1.464 1.175H4.802a1.5 1.5 0 01-1.464-1.175L1.512 6.108a.5.5 0 01.098-.42z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                            Add to cart
                                        </button>
                                        <div class="title-item-services name-service-single" (click)="goToSingle(41)">
                                            {{services.id41.name}}
                                        </div><br>
                                        <div class="price-item-services" (click)="goToSingle(41)">
                                            £{{services.id41.price}} +VAT
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<div class="avaliacoes-clientes">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="title-avaliacoes">What Client Says ?</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div id="carousel-avaliacoes" class="carousel slide" data-ride="carousel">
                        <ol class="carousel-indicators indicators-services">
                            <li data-target="#carousel-avaliacoes" data-slide-to="0" class="active"></li>
                            <li data-target="#carousel-avaliacoes" data-slide-to="1"></li>
                            <li data-target="#carousel-avaliacoes" data-slide-to="2"></li>
                            <li data-target="#carousel-avaliacoes" data-slide-to="3"></li>
                        </ol>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="row">
                                    <div class="col-md-6">
                                        <img src="assets/imagens/aspas.png" class="icon-aspas">
                                        <p class="text-avaliacoes">PhaseApp has always supported us in new electrical
                                            installation as well as a planned maintenance , also fitted our new Fire
                                            alarm
                                            system. We’ll recommended to all.</p>
                                        <hr class="linha-avaliacoes">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5 class="nome-avaliacao">Wender Gava</h5>
                                                <div class="icon-star">
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </div>
                                                <p class="cargo-avaliacao">Frigideira Restaurant - Kensal Rise</p>
                                            </div>
                                        </div>
                                        <hr class="linha-avaliacoes-final">
                                    </div>
                                    <div class="col-md-6">
                                        <img src="assets/imagens/aspas.png" class="icon-aspas">
                                        <p class="text-avaliacoes">PhaseApp has fully renovated the Electrical
                                            installation in
                                            our new
                                            Pizza/restaurant. Amazing lighting designs and accuracy at the final
                                            touches. We
                                            also have
                                            agreed a monthly maintenance and emergency call out for peace of mind.</p>
                                        <hr class="linha-avaliacoes">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5 class="nome-avaliacao">Junior - Cricklewood</h5>
                                                <div class="icon-star">
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </div>
                                                <p class="cargo-avaliacao">Copacabana Gourmet Pizza</p>
                                            </div>
                                        </div>
                                        <hr class="linha-avaliacoes-final">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="row">
                                    <div class="col-md-6">
                                        <img src="assets/imagens/aspas.png" class="icon-aspas">
                                        <p class="text-avaliacoes">PhaseApp has sorted my electric that was tripping due
                                            to a
                                            poor looses connection behind my electric Cooker. Rapid response and so
                                            reliable.
                                            Money well spent.</p>
                                        <hr class="linha-avaliacoes">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5 class="nome-avaliacao">Michael Gallop</h5>
                                                <div class="icon-star">
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </div>
                                                <p class="cargo-avaliacao">Horsecroft Road - Edgware</p>
                                            </div>
                                        </div>
                                        <hr class="linha-avaliacoes-final">
                                    </div>
                                    <div class="col-md-6">
                                        <img src="assets/imagens/aspas.png" class="icon-aspas">
                                        <p class="text-avaliacoes">PhaseApp has upgraded my Consumer Unit and changed a
                                            few
                                            damaged sockets in my house. The Electrician arrived on time and went
                                            straight to
                                            sort the problem as my Consumer Unit has signs of melting and smell of
                                            smoke. Both
                                            me and my brother has used PhaseApp and we are both very satisfied. Very
                                            recommended.</p>
                                        <hr class="linha-avaliacoes">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5 class="nome-avaliacao">Jeremy Gallop</h5>
                                                <div class="icon-star">
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </div>
                                                <p class="cargo-avaliacao">Forest Hill</p>
                                            </div>
                                        </div>
                                        <hr class="linha-avaliacoes-final">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="row">
                                    <div class="col-md-6">
                                        <img src="assets/imagens/aspas.png" class="icon-aspas">
                                        <p class="text-avaliacoes">I have had an electrician from PhaseApp to install a
                                            few more
                                            sockets in my house as there were insufficient to our demand. The electrical
                                            engineer was hands on work with no time wasted and always asked what was the
                                            best
                                            location to fit the new sockets to suit my needs. Really professional.</p>
                                        <hr class="linha-avaliacoes">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5 class="nome-avaliacao">John</h5>
                                                <div class="icon-star">
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </div>
                                                <p class="cargo-avaliacao">Kingsbury</p>
                                            </div>
                                        </div>
                                        <hr class="linha-avaliacoes-final">
                                    </div>
                                    <div class="col-md-6">
                                        <img src="assets/imagens/aspas.png" class="icon-aspas">
                                        <p class="text-avaliacoes">I had a problem with my bathroom extraction fan
                                            stopped
                                            working. I have used this PhaseApp and I was really surprised with the
                                            efficiency
                                            works to change the extractor without damaging my bathroom wall. Thanks a
                                            lot.</p>
                                        <hr class="linha-avaliacoes">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5 class="nome-avaliacao">Vanessa</h5>
                                                <div class="icon-star">
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </div>
                                                <p class="cargo-avaliacao">Burnt Oak</p>
                                            </div>
                                        </div>
                                        <hr class="linha-avaliacoes-final">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="row">
                                    <div class="col-md-6">
                                        <img src="assets/imagens/aspas.png" class="icon-aspas">
                                        <p class="text-avaliacoes">⁃ I have had an Electrical engineer from PhaseApp to
                                            install
                                            a new power shower In my house, also changed a defective light in the
                                            sitting room.
                                            Really happy with the works. Valeu for money</p>
                                        <hr class="linha-avaliacoes">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5 class="nome-avaliacao">Taiane Silva</h5>
                                                <div class="icon-star">
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    <svg class="bi bi-star-fill" width="1em" height="1em"
                                                        viewBox="0 0 16 16" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                </div>
                                                <p class="cargo-avaliacao">Colindale</p>
                                            </div>
                                        </div>
                                        <hr class="linha-avaliacoes-final">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
    <div class="container brands">
        <div class="row">
            <div class="col-md-12">
                <img src="assets/imagens/about_us_logos.jpg">
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>