import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-detail-pedido',
  templateUrl: './detail-pedido.component.html',
  styleUrls: ['./detail-pedido.component.css']
})
export class DetailPedidoComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public idOrder: any,
    private http: HttpClient
  ) { }

  urlBase = "https://phaseapp.uk/site/api_site/";
  produtosDoPedido: Array<any> = [];

  ngOnInit(): void {
    this.carregaDados();
  }

  carregaDados() {
    this.itensDoPedido();
  }

  itensDoPedido() {
    this.http.get(this.urlBase + "getPedidoFeito.php?order=" + this.idOrder.idOrder).subscribe(
      data => {
        let retorno = data as any;
        let itens = JSON.parse(retorno[0].ORD_ITENS);
        itens.forEach(element => {
          this.http.get(this.urlBase + "getProdutoId.php?idProduto=" + element.idProduto).subscribe(
            dataProduto => {
              let retornoProduto = dataProduto as any;
              this.produtosDoPedido.push(retornoProduto[0]);
            },
            errProduto => {
              console.log(errProduto);
            }
          );
        });
        console.log(this.produtosDoPedido);
      },
      err => {
        console.log(err);
      }
    );
  }

}
