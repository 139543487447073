import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { CartComponent } from './cart/cart.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { CadastroPageComponent } from './cadastro-page/cadastro-page.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { SearchComponent } from './search/search.component';
import { MinhaContaComponent } from './minha-conta/minha-conta.component';
import { CadastroEngineerComponent } from './cadastro-engineer/cadastro-engineer.component';
import { SingleServiceComponent } from './single-service/single-service.component';
import { GalleryComponent } from './gallery/gallery.component';
import { PagamentoDebitoComponent } from './pagamento-debito/pagamento-debito.component';
import { RecoverCustomerPasswordComponent } from './recover-customer-password/recover-customer-password.component';
import { ChangeCustomerPasswordComponent } from './change-customer-password/change-customer-password.component';



const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'cart', component: CartComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'cadastro', component: CadastroPageComponent },
  { path: 'about', component: AboutComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'search', component: SearchComponent },
  { path: 'myaccount', component: MinhaContaComponent },
  { path: 'engineer', component: CadastroEngineerComponent },
  { path: 'single', component: SingleServiceComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'debit', component: PagamentoDebitoComponent },
  { path: 'recoverpassword', component: RecoverCustomerPasswordComponent },
  { path: 'changecustomerpassword', component: ChangeCustomerPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
