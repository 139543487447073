import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-menu-topo',
  templateUrl: './menu-topo.component.html',
  styleUrls: ['./menu-topo.component.css']
})
export class MenuTopoComponent implements OnInit {

  constructor(
    private http: HttpClient) { }

  dadosLoginClientPhaseSite = null;
  firstName = null;

  urlBase = "https://phaseapp.uk/site/api_site/";

  ngOnInit(): void {
    this.dadosLoginClientPhaseSite = JSON.parse(localStorage.getItem("dadosLoginClientPhaseSite"));
    if (this.dadosLoginClientPhaseSite) {
      let dadosLoginClientPhaseSite = this.dadosLoginClientPhaseSite as any
      this.firstName = dadosLoginClientPhaseSite.CLI_FIRSTNAME;
      /*let email=dadosLoginClientPhaseSite.CLI_FIRSTNAME;
      let token=dadosLoginClientPhaseSite.CLI_FIRSTNAME;
      this.http.get(this.urlBase + "checkuser.php?email="++"&token="+).subscribe(
        data => {
          this.categorias = data;
        },
        error => {
          console.log(error);
        }
      );*/
    }
  }

  verificaLogin() {
    setInterval(() => {
      let dadosLoginClientPhaseSite = localStorage.getItem("dadosLoginClientPhaseSite");
      let jaVerificou = localStorage.getItem("verificouLoginPhaseAppClient");
      if (dadosLoginClientPhaseSite && !jaVerificou) {
        localStorage.setItem("verificouLoginPhaseAppClient", "true");
        window.location.reload();
      }
    }, 100);
  }

  logout() {
    window.location.href = "/logout.php";
  }

}
