import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-principal',
  templateUrl: './menu-principal.component.html',
  styleUrls: ['./menu-principal.component.css']
})
export class MenuPrincipalComponent implements OnInit {

  spinnerShow = false;

  cart = {order: null, services: new Array<any>()};
  quantity = 0;

  currentPage: number = 1;

  categorias = null;
  imagensCarousel = null;

  submenuclosed: boolean = true;

  urlBase = "https://phaseapp.uk/site/api_site/";
  urlImagens = "https://phaseapp.uk/site/imagens/";
  produtos;

  fieldPesquisa = "";
  fieldCategoria = "";

  bannerApp = "";

  numeroWhatsapp = '4407716395636';

  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) { }

  openServices(){
    this.submenuclosed = !this.submenuclosed;       
}

  ngOnInit(): void {
    this.carregaDados();
    this.loadCart();
  }

  loadCart(){
    this.cart = JSON.parse(localStorage.getItem("phaseAppCart"));
    if(!this.cart || !this.cart.services){
      this.cart = {order: null, services: new Array<any>()};
    }
    this.quantity = this.cart.services.length;
  }

  goToSingle(produto) {
    this.router.navigate(['/single'], { queryParams: { ID: produto.idprodutos } });
  }

  carregaCategorias() {
    this.http.get(this.urlBase + "getCategorias.php").subscribe(
      data => {
        this.categorias = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  carregaDados() {
    this.spinnerShow = true;
    this.updateCart();

    this.http.get(this.urlBase + "getProdutos.php").subscribe(
      data => {
        this.produtos = data;
        this.carregaCategorias();
        this.carregaImagens();
        this.spinnerShow = false;
      },
      error => {
        console.log(error);
        this.spinnerShow = false;
      }
    );

    this.http.get(this.urlBase + "getConfiguracoes.php").subscribe(
      data => {
        let retorno = data as any;
        this.bannerApp = retorno[0].CONFIG_IMG_BANNER;
      },
      err => {
        console.log(err);
      }
    );
  }

  openPlayStore() {
    window.open("https://play.google.com/store/apps/details?id=uk.co.phaseapp.app", "_blank");
  }

  openAppStore() {
    window.open("https://apps.apple.com/us/app/id1530706947", "_blank");
  }

  carregaImagens() {
    this.http.get(this.urlBase + "getImagensCarousel.php").subscribe(
      data => {
        this.imagensCarousel = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  updateCart() {
    if (this.cart.services) {
      this.quantity = this.cart.services.length;
    }else{
      this.quantity = 0;
    }
  }

  addItemCart(produto: any) {
    let cart = JSON.parse(localStorage.getItem("phaseAppCart"));
  
    if (cart) {
      cart.services.push(produto);
      localStorage.setItem("phaseAppCart", JSON.stringify(cart));
    }

    else {
      let arr: Array<any> = [];
      arr.push(produto);
      cart.services=arr;
      localStorage.setItem("phaseAppCart", JSON.stringify(cart));
    }

    this._snackBar.open('Added to cart!', 'Ok', { duration: 3000 });

    this.updateCart();
  }

  buscarFiltro() {
    if (this.fieldPesquisa || this.fieldCategoria) {
      this.router.navigate(['/search'], { queryParams: { cat: this.fieldCategoria, field: this.fieldPesquisa } });
    }
    else {
      this._snackBar.open('You need to fill in one of the fields for the search!', 'Ok', { duration: 3000 });
    }
  }

  whatsapp() {
    window.open("whatsapp://send?phone=" + this.numeroWhatsapp, '_system', 'location=yes');
  }

}

