<div id="login-menu">
    <div>
        <div>
            <div *ngIf="!dadosLoginClientPhaseSite" class="normal-main"><a href="/search?cat=2" class="button Red">Emergency</a><a class="login" [routerLink]="['/login']">Sign in</a><a [routerLink]="['/engineer']">Become an enginer</a></div>
            <div *ngIf="dadosLoginClientPhaseSite" class="normal-main"><a href="/phaseappboard/">Welcome {{firstName}}!</a> <a [routerLink]="['/login']" class="btnRed">Emergency</a><a class="btnLogOut" (click)="logout()">Log Out</a></div>
        </div>
        <div>
            <ul class="topo1-lista">
                <li><a *ngIf="dadosLoginClientPhaseSite" [routerLink]="['/myaccount']">My Account</a></li>
            </ul>
        </div>
    </div>
</div>