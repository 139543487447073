import { Component, OnInit, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormGroup, FormControl} from '@angular/forms';

@Component({
  selector: 'app-cadastro-engineer',
  templateUrl: './cadastro-engineer.component.html',
  styleUrls: ['./cadastro-engineer.component.css']
})
export class CadastroEngineerComponent implements OnInit {

  termsAndConditions = false;

  urlBase = "https://phaseapp.uk/site/api_site/";
  files=[];
  ngForm = new FormGroup({
    firstname: new FormControl(""),
    lastname: new FormControl(""),
    address1: new FormControl(""),
    address2: new FormControl(""),
    phone: new FormControl(""),
    city: new FormControl(""),
    postcode: new FormControl(""),
    email: new FormControl(""),
    password: new FormControl(""),
    file: new FormControl(''),
    fileSource: new FormControl('')
  });
  firstName = "";
  lastName = "";
  address1 = "";
  address2 = "";
  phone = "";
  townCity = "";
  postcode = "";
  email = "";
  certificates = "";
  password = ""
  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
    public element: ElementRef,
  ) { }

  get f(){
    return this.ngForm.controls;
  }

  onFileChange(event) {
    /*if (event.files && event.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount && i < 10; i++) {
        var reader = new FileReader();
        reader.onload = (event:any) => {
          console.log(event.target.result);
          this.files.push(event.target.result); 
          this.ngForm.patchValue({
            fileSource: this.files
          });
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }*/
    this.files=[];
    for (var i = 0; i < event.target.files.length && i < 10; i++) { 
      this.files.push(event.target.files[i]);
  }
  }

  spinnerShow = false;

  ngOnInit(): void {
  }

  doCadastro() {
    this.spinnerShow = true;
    const formData = new FormData();
    formData.append("firstName", this.firstName);
    formData.append("lastName", this.lastName);
    formData.append("address1", this.address1);
    formData.append("address2", this.address2);
    formData.append("phone", this.phone);
    formData.append("townCity", this.townCity);
    formData.append("postcode", this.postcode);
    formData.append("email", this.email);
    formData.append("password", this.password);
    for (var i = 0; i < this.files.length; i++) { 
      formData.append("file[]", this.files[i]);
    }
    this.http.post(this.urlBase + "apiCadastrarEngineer.php", formData).subscribe(
      data => {
        let retorno = data as any;
        console.log(retorno);
        if (retorno.success) {
          this.spinnerShow = false;
          this._snackBar.open('Registration successful! Wait and we will contact you!', 'Ok', { duration: 3000 });
          this.router.navigate(['/']);
        }
        else {
          this.spinnerShow = false;
          this._snackBar.open('You already have an account!', 'Ok', { duration: 3000 });
        }
      },
      error => {
        console.log(error);
        this.spinnerShow = false;
      }
    );
  }
}
