<div class="conteudo-site">
    <app-menu-topo></app-menu-topo>
    <app-menu-principal></app-menu-principal>  
    <div class="faixa-azul">
        <h5>Home > About us</h5>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="title-about">Images Gallery</h1>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <!-- <mdb-image-modal [modalImages]="imagesBasic" type="no-margin"></mdb-image-modal> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>