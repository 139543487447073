//import { Component, OnInit } from '@angular/core';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


declare var paypal;

@Component({
  selector: 'app-finalizar-agendamento',
  templateUrl: './finalizar-agendamento.component.html',
  styleUrls: ['./finalizar-agendamento.component.css']
})
export class FinalizarAgendamentoComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: {sheet: MatBottomSheet, services: any},
  ) { }

  urlBase = "https://phaseapp.uk/site/api_site/";
  valorTotal = 0.00;
  cart: any = {order: null, services: new Array<any>()};
  spinnerShow = false;
  addScript = false;
  dadosLoginClientPhaseSite = null;
  websiteCalled=false;

  ngOnInit(): void {
    this.carregaDados();
  }

  carregaDados() {
    this.dadosLoginClientPhaseSite = JSON.parse(localStorage.getItem("dadosLoginClientPhaseSite"));
    this.cart=JSON.parse(localStorage.getItem("phaseAppCart"));
    this.valorTotal = parseFloat(this.cart.totalToPay);
  }

  paypalConfig = {
    env: 'production',
    client: {
      sandbox: 'ASYld0FsTRlYq-EPFChBkgc1YDNpeUXaHpmOPbeLrCfS2h_m1wUeCVd1jrwcEQNkeaEa1SQ-dMYWckoE',
      production: 'AcfMLJefu_vik9nV8IVpp-l3eZ0ztGHeGRgbb9m68cftOiuNikEnxkWClPeDe9l7fbQ7t6mHPsbaetP3'
    },
    commit: true,
    payment: (data, actions) => {
      return actions.payment.create({
        payment: {
          transactions: [
            {
              amount: {
                total: this.valorTotal,
                currency: 'GBP'
              },
              description: JSON.stringify(this.data.services),
              invoice_number: this.cart.order
            }
          ]
        }
      });
    },
    onAuthorize: (data, actions) => { 
      this.spinnerShow = true;
      let invoice=encodeURI(JSON.stringify(this.cart));
      alert('https://phaseapp.uk/api/paymentAccepted.php?orderId=' + this.cart.order + '&invoice=' +invoice);
      console.log('https://phaseapp.uk/api/paymentAccepted.php?orderId=' + this.cart.order + '&invoice=' +invoice);
      return actions.payment.execute().then((payment) => {
        this.http.get('https://phaseapp.uk/api/paymentAccepted.php?orderId=' + this.cart.order + '&invoice=' +invoice, {responseType: 'text'}).subscribe(
            data => {
            this.spinnerShow = false;
            this._snackBar.open('Your order has been successfully completed! Wait and we will contact you!', 'Ok', { duration: 6000 });
            localStorage.removeItem("phaseAppCart");
            this.data.sheet.dismiss();
            this.router.navigate(['/']);
          },
          error => {
            console.log(error);
            this.spinnerShow = false;
          }
        );
      });
    }
  }

  showError(error):void{
    this.spinnerShow = false;
  }

  ngAfterViewChecked(): void {
    if(!this.websiteCalled){
      this.websiteCalled=true;
      if (!this.addScript) {
        this.addPaypalScript().then(() => {
          paypal.Button.render(this.paypalConfig, '#paypal-checkout-btn');
        });
      }
    }
  }

  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scriptTagElement = document.createElement('script');
      scriptTagElement.src = 'https://www.paypalobjects.com/api/checkout.js';
      scriptTagElement.onload = resolve;
      document.body.appendChild(scriptTagElement);
    })
  }

  updateOrder(id: number){
    this.cart.order=id;
    localStorage.setItem("phaseAppCart", JSON.stringify(this.cart));
  }

}
