import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-single-service',
  templateUrl: './single-service.component.html',
  styleUrls: ['./single-service.component.css']
})
export class SingleServiceComponent implements OnInit {

  spinnerShow = false;

  urlBase = "https://phaseapp.uk/site/api_site/";
  urlImagens = "https://phaseapp.uk/site/imagens/";
  produto;

  
  cart = {order: null, services: new Array<any>()};
  quantity = 0;

  currentPage: number = 1;

  idProduto = null;

  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.carregaDados();
    this.loadCart();
  }

  loadCart(){
    this.cart = JSON.parse(localStorage.getItem("phaseAppCart"));
    if(!this.cart || !this.cart.services){
      this.cart = {order: null, services: new Array<any>()};
    }
  }

  carregaDados() {
    this.spinnerShow = true;
    this.updateCart();
    this.route.queryParams.subscribe(params => {
      this.idProduto = params['id'];

      this.http.get(this.urlBase + "apiSingle.php?idProduto=" + params['id']).subscribe(
        data => {
          let retorno = data as any
          this.produto = retorno[0];
          this.spinnerShow = false;
        },
        error => {
          console.log(error);
          this.spinnerShow = false;
        }
      );
    });
  }

  updateCart() {
    if (this.cart.services) {
      this.quantity = this.cart.services.length;
    }else{
      this.quantity = 0;
    }
  }

  addItemCart(id:number) {
    if(!this.cart.services){
      this.cart.services=new Array<any>();
    }
    let found=false;
    let length=this.cart.services.length;
    let i=0;
    while(i<length && !found){
      if(this.cart.services[i].ID==id){
        found=true;
        this.cart.services[i].quantity++;
      }
      i++;
    }
    if(!found){
      let service={ID:id,quantity:1};
      this.cart.services.push(service);
    }
    console.table(this.cart);
    localStorage.setItem("phaseAppCart", JSON.stringify(this.cart));
    console.table(JSON.parse(localStorage.getItem("phaseAppCart")));
    this._snackBar.open('Added to cart!', 'Ok', { duration: 3000 });
    this.updateCart();
  }
}
