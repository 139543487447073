import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FinalizarAgendamentoComponent } from '../finalizar-agendamento/finalizar-agendamento.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
//import { table } from 'console';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  spinnerShow = null;

  constructor(
    private _bottomSheet: MatBottomSheet,
    private _snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient
  ) { }

  //totalToPay = 0.00;

  date = null;
  time = null;

  //pacotePedidoFeitoDebitCard = null;
  dadosLoginClientPhaseSite = null;

  dateTimeServer = null;

  cardNumber = null;
  cardDateValidate: string = null;
  cardCvn = null;
  cardHolderName = null;

  //dayName = new Array("domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado");
  monthName = new Array("01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12");

  cart: any = {order: null, services: new Array<any>()};
  details;
  //produtosCarrinho: Array<any> = [];
  urlBase = "https://phaseapp.uk/site/api_site/";
  urlApi = "https://phaseapp.uk/api/";
  urlImages = "https://phaseapp.uk/assets/images/";

  currentPage: number = 1;

  ngOnInit(): void {
    this.getDate();
    this.loadCart();
  }

  loadCart(){
    this.cart = JSON.parse(localStorage.getItem("phaseAppCart"));
    if(!this.cart || !this.cart.services){
      this.cart = {order: null, services: new Array<any>()};
    }else{
      let ids=Array();
      this.cart.services.forEach(element => {
        ids.push(element.ID);
      });
      this.http.get(this.urlApi + "getServicesDetails.php?ids=" + encodeURI(JSON.stringify(ids))).subscribe(
        (data) => {
          this.details=data;
          this.details.details.forEach(service => {
           this.updateServiceDetails(service);
          });
          this.cart.services=this.details.details;
          this.calculateTotal();
        },
        err => {
          console.log(err);
          this.spinnerShow = false;
        }
      )
    }
  }

  updateServiceDetails(service){
    service.ID=Number(service.ID);
    let found=false;
    let i=0;
    while(!found && i<this.cart.services.length){
      if(this.cart.services[i].ID==service.ID){
        service.quantity=this.cart.services[i].quantity;
        service.price=Number(service.price);
        service.vat=Number(service.vat);
        this.cart.services.splice(i,1);
        this.calculateTotalService(service);
        found=true;
      }
      i++;
    }
  }

  calculateTotalService(service) {
    this.calculateVat(service);
    let final=service.price+service.vatValue;
    service.total=final*service.quantity;
    return service.total;
  }

  calculateVat(service){
    let vat=service.vat/100;
    service.vatValue=Number((service.price*vat).toFixed(2));
    return service.vatValue;
  }

  changeQuantity(service,change){
    service.quantity+=change;
    if(service.quantity<1){
      service.quantity=1;
    }
    this.calculateTotalService(service);
    this.calculateTotal();
  }

  getDate(){
    this.dateTimeServer = new Date();
    this.http.get(this.urlBase + "getDateAndHour.php").subscribe(
      data => {
        let response = data as any;
        let hour = response.time.substr(0, 2);
        let minutes = response.time.substr(3, 2);
        var arrDataExclusao = response.dateMin.split('/');
        this.dateTimeServer = new Date(arrDataExclusao[2], arrDataExclusao[1] - 1, arrDataExclusao[0], hour, minutes);
      },
      err => {
        console.log(err);
        this.dateTimeServer = new Date();
      }
    );
  }

  calculateTotal() {
    let total=0.00;
    if (this.cart.services) {
      this.cart.services.forEach(element => {
        if (element.total) {
          total += element.total;
        }
        else {
          total += this.calculateTotalService(element);
        }
      });
    }
    this.cart.totalToPay=Number(total.toFixed(2));
    localStorage.setItem("phaseAppCart", JSON.stringify(this.cart));
  }


  deleteItem(service) {
    service.ID=Number(service.ID);
    let found=false;
    let i=0;
    while(!found && i<this.cart.services.length){
      if(this.cart.services[i].ID==service.ID){
        this.cart.services.splice(i,1);
        localStorage.setItem("phaseAppCart", JSON.stringify(this.cart));
        found=true;
      }
      i++;
    }
    this.calculateTotal();
  }

  bookServices(method){
    if (localStorage.getItem("dadosLoginClientPhaseSite")) {
      let partesData = this.date.split("/");
      let dateDigitada = new Date(partesData[2], partesData[1] - 1, partesData[0]);

      let servEmergency: Array<any> = [];
      let servOther: Array<any> = [];

      let horaDigitada = this.time.substr(0, 2);
      let minutosDigitados = this.time.substr(3, 2);

      let tempoDigitado = new Date(partesData[2], partesData[1] - 1, partesData[0], horaDigitada, minutosDigitados);

      let dataAtual = this.dateTimeServer.getFullYear() + '-' + this.dateTimeServer.getMonth() + '-' + this.dateTimeServer.getDate();
      let dataDigitada = tempoDigitado.getFullYear() + '-' + tempoDigitado.getMonth() + '-' + tempoDigitado.getDate();

      this.cart.services.forEach(element => {
        if (element.category == '2') {
          servEmergency.push(element);
        }
        else {
          servOther.push(element);
        }
      });

      if (tempoDigitado < this.dateTimeServer) {
        this._snackBar.open('You cannot choose a date less than today!', 'Ok', { duration: 3000 });
      }

      else if ((partesData[1] - 1) > (12)) {
        this._snackBar.open('Invalid date!', 'Ok', { duration: 3000 });
      }

      else if (partesData[0] > 31) {
        this._snackBar.open('Invalid date!', 'Ok', { duration: 3000 });
      }

      /*else if ((servEmergency.length) > 0 && (servOther.length == 0)) {
        this.bookPayPal(tempoDigitado, horaDigitada, minutosDigitados);
      }*/

      else if (tempoDigitado.getDay() == 0) {
        this._snackBar.open('Unable to schedule for Sunday!', 'Ok', { duration: 3000 });
      }

      else if ((tempoDigitado.getDay() == 6) && horaDigitada > 12) {
        this._snackBar.open('It is not possible to schedule for Saturday after 12pm!', 'Ok', { duration: 3000 });
      }

      else if ((tempoDigitado.getDay() == 6) && horaDigitada < 8) {
        this._snackBar.open('It is not possible to schedule a Saturday before 8am!', 'Ok', { duration: 3000 });
      }

      else if ((horaDigitada > 24) || (minutosDigitados > 59)) {
        this._snackBar.open('Invalid time!', 'Ok', { duration: 3000 });
      }

      else if ((horaDigitada < 8) || (horaDigitada > 17)) {
        this._snackBar.open('Scheduling allowed only between 8 am and 5 pm!', 'Ok', { duration: 3000 });
      }

      else if ((horaDigitada == 17) && (minutosDigitados > 0)) {
        this._snackBar.open('Scheduling allowed only between 8 am and 5 pm!', 'Ok', { duration: 3000 });
      }

      else if ((dataAtual == dataDigitada) && (horaDigitada < (this.dateTimeServer.getHours() + 2))) {
        this._snackBar.open('We need at least 2 hours to be able to serve you!', 'Ok', { duration: 3000 });
      }

      else {
        let bookingDate = tempoDigitado.getFullYear() + "-" + this.monthName[tempoDigitado.getMonth()] + "-" + tempoDigitado.getDate() + " " + horaDigitada + ":" + minutosDigitados;
        this.book(bookingDate,method);
      }
    }
    else {
      this._snackBar.open('You must be logged in to complete the operation!', 'Ok', { duration: 3000 });
      this.router.navigate(['/login']);
    }
  }


  preInsertOrder(method){
    let id="";
    if(this.cart.order){
      id='&orderID='+this.cart.order;
    }
    this.http.get('https://phaseapp.uk/api/addPrePaidOrder.php?customerID=' + this.cart.customerID + '&services=' + JSON.stringify(this.cart.services) + '&date=' + this.cart.bookingData + '&total=' + this.cart.totalToPay.toFixed(2)+id, {responseType: 'text'}).subscribe(
      data => {
        let response=JSON.parse(data);
        if(response.success){
          this.cart.order=response.order;
          localStorage.setItem("phaseAppCart", JSON.stringify(this.cart));
          this.spinnerShow = false;
          switch(method){
            case 'PayPal':
              this.payPayPal(response.services);
              break;
            case 'Card':
              document.getElementById("openModalButton").click();
              break;
          }
        }else{
          this.showError(response.error);
        }
      },
      error => {
        this.showError(error);
      }
    );
  }

  book(bookingDate,method){
    this.cart.bookingData=bookingDate;
    this.cart.customerID=JSON.parse(localStorage.getItem("dadosLoginClientPhaseSite")).idclientes;
    this.preInsertOrder(method);
  }
  

  payPayPal(services){
    if(services){
      let finalizaAgendamentoComponent = this._bottomSheet.open(FinalizarAgendamentoComponent, {
        data: { sheet: this._bottomSheet, services: services},
      });
    }
  }

  payCard() {
    this.spinnerShow = true;
    var cardNumber = this.cardNumber.replaceAll(" ", "");
    var expMonth = this.cardDateValidate.substr(0, 2);
    var expYear = this.cardDateValidate.substr(3, 4);
    
    this.http.get(this.urlBase + "apiPagamentoDebito/apiPaymentDebitCard.php?cardNumber=" + cardNumber + "&expMonth=" + expMonth + "&expYear=" + expYear + "&cvn=" + this.cardCvn + "&cardHolderName=" + this.cardHolderName + "&totalValue=" + this.cart.totalToPay, { responseType: "text" }).subscribe(
      data => {
        let retorno: string = data as any;
        let invoice=encodeURI(JSON.stringify(this.cart));
        alert('https://phaseapp.uk/api/paymentAccepted.php?orderId=' + this.cart.order + '&invoice=' +invoice);
        if (retorno.search("AUTH CODE") != -1) {
          this.http.get('https://phaseapp.uk/api/paymentAccepted.php?orderId=' + this.cart.order + '&invoice=' +invoice, {responseType: 'text'}).subscribe(
            data => {
              this.spinnerShow = false;
              this._snackBar.open('Your order has been successfully completed! Wait and we will contact you!', 'Ok', { duration: 6000 });
              localStorage.removeItem("phaseAppCart");
              document.getElementById("closeModalButton").click();
              this.router.navigate(['/']);
            },
            error => {
              console.log(error);
              this.spinnerShow = false;
            }
          );
        }
        else {
          this._snackBar.open('Your payment was not accepted. Try again!', 'Ok', { duration: 3000 });
          //alert('Your payment was not accepted. Try again!');
          console.log("error="+retorno);
          this.spinnerShow = false;
        }
      },
      err => {
        console.log(err);
        this.spinnerShow = false;
      }
    )
  }

  showError(error):void{
    console.log(error);
    this.spinnerShow = false;
  }
}
