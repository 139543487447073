import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  urlBase = "https://phaseapp.uk/site/api_site/";
  urlImagens = "https://phaseapp.uk/site/imagens/";

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) { }

  idCategoria = null;
  fieldPesquisa = null;

  spinnerShow = false;
  cart = {order: null, services: new Array<any>()};
  catNome = null;

  produtos = null;

  currentPage: number = 1;
  quantity = 0;

  ngOnInit(): void {
    this.carregaDados();
  }

  goToSingle(produto) {
    this.router.navigate(['/single'], { queryParams: { id: produto.idprodutos } });
  }

  carregaDados() {
    this.spinnerShow = true;
    this.loadCart();
    this.route.queryParams.subscribe(params => {
      this.idCategoria = params['cat'];
      this.fieldPesquisa = params['field'];
      if (params['cat'] && params['field']) {
        this.http.get(this.urlBase + "buscaCategoriaECampo.php?catId=" + params['cat'] + "&field=" + params['field']).subscribe(
          data => {
            console.log("data",data);
            this.produtos = data;
            console.table(this.produtos);
            this.spinnerShow = false;
          },
          error => {
            console.log(error);
            this.spinnerShow = false;
          }
        );
      }
      else if (params['cat']) {
        this.http.get(this.urlBase + "getFilterCategoria.php?catId=" + params['cat']).subscribe(
          data => {
            console.log("data",data);
            this.produtos = data;
            this.spinnerShow = false;
          },
          error => {
            console.log(error);
            this.spinnerShow = false;
          }
        );
      }
      else if (params['field']) {
        this.http.get(this.urlBase + "getFieldCampoPesquisa.php?field=" + params['field']).subscribe(
          data => {
            console.log("data",data);
            this.produtos = data;
            this.spinnerShow = false;
          },
          err => {
            console.log(err);
            this.spinnerShow = false;
          }
        );
      }
      else {
        this.router.navigate(['/search']);
      }
    });

  }

  updateCart() {
    if (this.cart.services) {
      this.quantity = this.cart.services.length;
    }else{
      this.quantity = 0;
    }
  }

  loadCart(){
    this.cart = JSON.parse(localStorage.getItem("phaseAppCart"));
    if(!this.cart || !this.cart.services){
      this.cart = {order: null, services: new Array<any>()};
    }
    this.quantity = this.cart.services.length;
  }

 /*addItemCart(produto: any) {
    let cart = JSON.parse(localStorage.getItem("phaseAppCart"));

    if (cart.services) {
      cart.services.push(produto);
      localStorage.setItem("phaseAppCart", JSON.stringify(cart));
    }

    else {
      let arr: Array<any> = [];
      arr.push(produto);
      cart.services=arr;
      localStorage.setItem("phaseAppCart", JSON.stringify(cart));
    }

    this._snackBar.open('Added to cart!', 'Ok', { duration: 3000 });

    this.updateCart();
  }*/

  addItemCart(id:number) {
    if(!this.cart.services){
      this.cart.services=new Array<any>();
    }
    let found=false;
    let length=this.cart.services.length;
    let i=0;
    while(i<length && !found){
      if(this.cart.services[i].ID==id){
        found=true;
        this.cart.services[i].quantity++;
      }
      i++;
    }
    if(!found){
      let service={ID:id,quantity:1};
      this.cart.services.push(service);
    }
    console.table(this.cart);
    localStorage.setItem("phaseAppCart", JSON.stringify(this.cart));
    console.table(JSON.parse(localStorage.getItem("phaseAppCart")));
    this._snackBar.open('Added to cart!', 'Ok', { duration: 3000 });
    this.updateCart();
  }

}
