import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-recover-customer-password',
  templateUrl: './recover-customer-password.component.html',
  styleUrls: ['./recover-customer-password.component.css']
})
export class RecoverCustomerPasswordComponent implements OnInit {

  urlBase = "https://phaseapp.uk/site/api_site/";

  spinnerShow = false;

  email = "";

  constructor(
    private http: HttpClient,
    private router: Router,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  recoverPassword() {
    this.spinnerShow = true;
    this.http.get(this.urlBase + "askNewPassword.php?email=" + this.email+"&usertype=customer" ).subscribe(
      data => {
        let retorno = data as any;
        if (retorno.success) {
          this.spinnerShow = false;
          this.router.navigate(['/']);
        }
        else {
          this.spinnerShow = false;
          this._snackBar.open('Invalid e-mail!', 'Ok', { duration: 3000 });
        }
      },
      error => {
        console.log(error);
        this.spinnerShow = false;
      }
    );
  }

}