<div id="spinner" *ngIf="spinnerShow">
    <mat-spinner></mat-spinner>
</div>

<div class="conteudo-site">
    <app-menu-topo></app-menu-topo>
    <app-menu-principal></app-menu-principal>
    <div class="faixa-azul">
        <h5>Home > Cart</h5>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <app-categorias></app-categorias>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-12">
                        <pagination-controls class="pagination-products-cart" (pageChange)="currentPage = $event">
                        </pagination-controls>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 20px;" *ngFor="let service of cart.services | paginate: { itemsPerPage: 8, currentPage: currentPage }">
                    <div class="col-md-4">
                        <div *ngIf="!service.img" class="img-cart-produto" [ngStyle]="{'background-image' : 'url('+ urlImages +'img-item-services.jpg)'}"></div>
                        <div *ngIf="service.img" class="img-cart-produto" [ngStyle]="{'background-image' : 'url(' + urlImages + service.img + ')'}"></div>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-12">
                                <h2>{{service.name}}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="descricao-service">{{service.description}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="price-item-services">£{{service.price}} +£{{service.vatValue}} VAT</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="container-qtd">
                                    <button class="menos" (click)="changeQuantity(service,-1)">-</button>
                                    <p class="">{{service.quantity}}</p>
                                    <button class="mais" (click)="changeQuantity(service,+1)">+</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="container-qtd">
                                    <p class="">Total = £{{service.total}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button matTooltip="Delete item from cart" (click)="deleteItem(service)" class="delete-cart-item"><span class="material-icons">delete</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <pagination-controls class="pagination-products-cart" (pageChange)="currentPage = $event">
                        </pagination-controls>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="total-cart-valor">Total: £{{cart.totalToPay}}</p>
                    </div>
                </div>
                <div class="row" *ngIf="cart.totalToPay">
                    <div class="col-md-12">
                        <form #formFinalizarPagamento="ngForm" class="form-agendar" novalidate>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="w-100">
                                        <mat-label>Choose a date</mat-label>
                                        <input mask="00/00/0000" [dropSpecialCharacters]="false" matInput #campoDate="ngModel" type="text" name="date" [(ngModel)]="date" ngModel required required>
                                    </mat-form-field>
                                    <span class="error-input" *ngIf="!campoDate.valid && (campoDate.dirty || campoDate.touched)">Required!</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="w-100">
                                        <mat-label>Choose a time</mat-label>
                                        <input mask="00:00" [dropSpecialCharacters]="false" matInput #campoTime="ngModel" type="text" name="time" [(ngModel)]="time" ngModel required required>
                                    </mat-form-field>
                                    <span class="error-input" *ngIf="!campoTime.valid && (campoTime.dirty || campoTime.touched)">Required!</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="note-client">Dear Client, please note: Our booking times are for guidance for our engineer, all our bookings for Am time will be attending between 8:00 and 12:00Am. For Pm bookings will be from 12:00 to 17:00pm.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- <div *ngIf="valorTotal" id="paypal-checkout-btn"></div> -->
                                    <button mat-raised-button [disabled]="!formFinalizarPagamento.valid" (click)="bookServices('PayPal')" color="primary" class="btn-padrao-site">PayPal</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button mat-raised-button (click)="bookServices('Card')" [disabled]="!formFinalizarPagamento.valid" style="margin-top: 20px !important;" color="primary" class="btn-padrao-site">Pay
                                        with debit card</button>
                                    <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#ModalDebitCard">Open Modal</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>


<!-- Modal -->
<div class="modal fade" id="ModalDebitCard" tabindex="-1" aria-labelledby="ModalDebitCardLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <form #formDebitCard="ngForm" class="form-debit-card">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="w-100">
                                    <mat-label>Card number</mat-label>
                                    <input matInput #campoCardNumber="ngModel" type="tel" name="cardNumber" [(ngModel)]="cardNumber" ngModel required required>
                                </mat-form-field>
                                <span class="error-input" *ngIf="!campoCardNumber.valid && (campoCardNumber.dirty || campoCardNumber.touched)">Required!</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="w-100">
                                    <mat-label>Validate (MM/YYYY)</mat-label>
                                    <input mask="00/0000" [dropSpecialCharacters]="false" matInput #campoDateValidate="ngModel" type="tel" name="cardDateValidate" [(ngModel)]="cardDateValidate" ngModel required required>
                                </mat-form-field>
                                <span class="error-input" *ngIf="!campoDateValidate.valid && (campoDateValidate.dirty || campoDateValidate.touched)">Required!</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="w-100">
                                    <mat-label>CVN</mat-label>
                                    <input matInput #campoCardCvn="ngModel" type="tel" name="cardCvn" [(ngModel)]="cardCvn" ngModel required required>
                                </mat-form-field>
                                <span class="error-input" *ngIf="!campoCardCvn.valid && (campoCardCvn.dirty || campoCardCvn.touched)">Required!</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="w-100">
                                    <mat-label>Card Holder Name</mat-label>
                                    <input matInput #campoCardHolderName="ngModel" type="text" name="cardHolderName" [(ngModel)]="cardHolderName" ngModel required required>
                                </mat-form-field>
                                <span class="error-input" *ngIf="!campoCardHolderName.valid && (campoCardHolderName.dirty || campoCardHolderName.touched)">Required!</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button mat-raised-button [disabled]="!formDebitCard.valid" color="primary" class="btn-padrao-site" (click)="payCard()">Pay</button>
                            </div>
                            <button id="closeModalButton" [hidden]="true" type="button" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>