<div class="content-categorias">
    <div class="header-categories-home">
        <h5>All categories</h5>
    </div>
    <ul class="list-categories" *ngIf="categorias1" style="margin-bottom: 0;">
        <div class="item-categoria" *ngFor="let categoria of categorias1; let i = index">
            <li *ngIf="categoria.idcategorias_services == 2 && (i < categoriasShow)" class="categoria-emergencia">
                <a [routerLink]="['/search']"
                    [queryParams]="{cat: categoria.idcategorias_services}">{{categoria.CAT_NOME}}</a>
            </li>
            <!-- CATEGORIA SELECIONADA -->
            <li
                *ngIf="(catId == categoria.idcategorias_services) && (categoria.idcategorias_services != 2) && (i < categoriasShow)">
                <a [routerLink]="['/search']" class="categoria-clicada"
                    [queryParams]="{cat: categoria.idcategorias_services}">{{categoria.CAT_NOME}} <span>>></span></a>
            </li>
            <li
                *ngIf="(catId != categoria.idcategorias_services) && (categoria.idcategorias_services != 2) && (i < categoriasShow)">
                <a [routerLink]="['/search']"
                    [queryParams]="{cat: categoria.idcategorias_services}">{{categoria.CAT_NOME}}</a>
            </li>
        </div>
    </ul>

    <!-- MENU ABERTO PARA PÁGINA SEARCH -->

    <ul class="list-categories" *ngIf="categorias1 && catId" style="margin-top: 0;">
        <div class="item-categoria" *ngFor="let categoria of categorias1; let i = index">
            <li *ngIf="categoria.idcategorias_services == 2 && (i >= categoriasShow)">
                <a [routerLink]="['/search']"
                    [queryParams]="{cat: categoria.idcategorias_services}">{{categoria.CAT_NOME}}</a>
            </li>
            <!-- CATEGORIA SELECIONADA -->
            <li
                *ngIf="(catId == categoria.idcategorias_services) && (categoria.idcategorias_services != 2) && (i >= categoriasShow)">
                <a [routerLink]="['/search']" class="categoria-clicada"
                    [queryParams]="{cat: categoria.idcategorias_services}">{{categoria.CAT_NOME}} <span>>></span></a>
            </li>
            <li
                *ngIf="(catId != categoria.idcategorias_services) && (categoria.idcategorias_services != 2) && (i >= categoriasShow)">
                <a [routerLink]="['/search']"
                    [queryParams]="{cat: categoria.idcategorias_services}">{{categoria.CAT_NOME}}<span
                    class="new-info-categories" *ngIf="categoria.idcategorias_services == 11">new</span></a>
            </li>
        </div>
    </ul>

    <!-- MENU ESCONDIDO -->

    <div class="collapse" id="collapseCategorias" *ngIf="!catId">
        <ul class="list-categories" *ngIf="categorias1" style="margin-top: 0;">
            <div class="item-categoria" *ngFor="let categoria of categorias1; let i = index">
                <li *ngIf="categoria.idcategorias_services == 2 && (i >= categoriasShow)" class="categoria-emergencia">
                    <a [routerLink]="['/search']"
                        [queryParams]="{cat: categoria.idcategorias_services}">{{categoria.CAT_NOME}}</a>
                </li>
                <!-- CATEGORIA SELECIONADA -->
                <li
                    *ngIf="(catId == categoria.idcategorias_services) && (categoria.idcategorias_services != 2) && (i >= categoriasShow)">
                    <a [routerLink]="['/search']" class="categoria-clicada"
                        [queryParams]="{cat: categoria.idcategorias_services}">{{categoria.CAT_NOME}}
                        <span>>></span></a>
                </li>
                <li
                    *ngIf="(catId != categoria.idcategorias_services) && (categoria.idcategorias_services != 2) && (i >= categoriasShow)">
                    <a [routerLink]="['/search']"
                        [queryParams]="{cat: categoria.idcategorias_services}">{{categoria.CAT_NOME}}<span
                        class="new-info-categories" *ngIf="categoria.idcategorias_services == 11">new</span></a>
                </li>
            </div>
        </ul>
    </div>
    <div class="header-categories-footer" *ngIf="!catId">
        <h5 data-toggle="collapse" href="#collapseCategorias" role="button" aria-expanded="false"
            aria-controls="collapseCategorias">+ More categories</h5>
    </div>
</div>