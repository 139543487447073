<div class="conteudo-site">
    <app-menu-topo></app-menu-topo>
    <app-menu-principal></app-menu-principal>  
    <div class="faixa-azul">
        <h5>Home > About us</h5>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="title-about">About Us</h1>
                <p>PhaseApp is a well-established business that strives to provide quality-assured services with more
                    than 17 years of experience. PhaseApp have built a strong client base and reputation across London
                    and surrounding areas. </p>
                <p>We carry out all domestic and commercial electrical installations and electrical testing; committed
                    to providing excellence.</p>

                <h3 class="title-about">MISSION STATEMENT</h3>
                <p>PhaseApp is dedicated to providing the highest quality electrical services throughout all domestic
                    and commercial projects. Our team of highly skilled electricians work within the British Standard
                    7671 regulations to see your project through from start to finish.</p>

                <h3 class="title-about">QUALITY</h3>
                <p>Every project PhaseApp undertakes is completed by our team of highly skilled electricians with the
                    best equipment and knowledge available. We are part of Phase Contractors Limited Company who
                    endeavour to exceed our customers’ expectations. Our electricians must complete a safety training
                    program before they can attend site.
                </p>

                <h3 class="title-about">CUSTOMER SERVICE</h3>
                <p>Our customers are critical to us. Our dedicated customer services is here to work alongside you to
                    ensure your installation has been completed at your taste.</p>

                <h3 class="title-about">ACCREDITATIONS</h3>
                <p>PhaseApp is a member of the highest quality assurances companies in the UK.</p>

                <p style="font-family: 'PantonBlackCaps';">Contact: info@phaseapp.uk</p>

                <img src="assets/imagens/about_us_logos.jpg"
                    style="width: 100%; margin-bottom: 50px; margin-top: 20px;">
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>