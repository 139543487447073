<div id="menu">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <a href="/"><img id="img-menu" src="assets/imagens/logo-topo.png" alt="Phase App Logo"></a>
            </div>
            <div class="col-md-4">
                <ul class="main-menu">
                    <li><a href="/">HOME</a></li>
                    <li><a href="/about">ABOUT US</a></li>
                    <li><a (click)="openServices()">SERVICES</a>
                        <div id="desktopSubMenu" class="row">
                            <ul class="main-content-wrapper" [ngClass]="submenuclosed ? 'success' : 'open'">
                                <li><a href="/search?cat=2">Emergency Services</a></li>
                                <li><a href="/search?cat=3">Electrical Certificates</a></li>
                                <li><a href="/search?cat=4">Consumer Units</a></li>
                                <li><a href="/search?cat=5">Fire Alarms</a></li>
                                <li><a href="/search?cat=6">Sockets</a></li>
                                <li><a href="/search?cat=7">Lights</a></li>
                                <li><a href="/search?cat=8">Switches</a></li>
                                <li><a href="/search?cat=9">Extractor Fans</a></li>
                                <li><a href="/search?cat=11">Electric Car Charging Point</a></li>
                                <li><a href="/search?cat=12">Connect Appliances</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-4">
                <div class="btn-topo-cart-wp">
                    <div class="barra-pesquisa">
                        <div class="pesquisar">
                            <form #formPesquisaTopo="ngForm" class="form-pesquisa-topo" novalidate>
                                <button class="btn-pesquisar" (click)="buscarFiltro()">
                                    <span class="material-icons">
                                        search
                                    </span>
                                </button>
                                <input class="search" type="text" #campoPesquisa="ngModel" name="fieldPesquisa" [(ngModel)]="fieldPesquisa">
                            </form>
                        </div>
                    </div>
                    <div>
                        <div class="content-btn-cart">
                            <button class="button-cart-topo btn-whatsapp btn-whatsapp-desktop" (click)="whatsapp()">
                                <i class="fab fa-whatsapp"></i>
                            </button>
                        </div>
                    </div>
                    <div>
                        <div class="content-btn-cart">
                            <a class="button-cart-topo" [routerLink]="['/cart']"><i
                                    class="material-icons">shopping_cart</i></a>
                            <span class="badge-cart">{{quantity}}</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div id="mobilemenu">
    <div>
        <div class="row">
            <div class="left">
                <a href="/"><img id="img-menu" src="assets/imagens/logo-topo.png" alt="Phase App Logo"></a>
            </div>
            <div class="main-menu">
                <input type="checkbox" id="openSidebarMenu">
                <label for="openSidebarMenu" class="sidebarIconToggle">
                    <div class="spinner diagonal part-1"></div>
                    <div class="spinner horizontal"></div>
                    <div class="spinner diagonal part-2"></div>
                </label>
                <div id="blurmenu"></div>
                <ul id="sidebarMenu">
                    <li><a href="/">HOME</a></li>
                    <li><a href="/about">ABOUT US</a></li>
                    <li><a (click)="openServices()">SERVICES</a>
                        <ul class="main-content-wrapper">
                            <li><a href="/search?cat=2">Emergency Services</a></li>
                            <li><a href="/search?cat=3">Electrical Certificates</a></li>
                            <li><a href="/search?cat=4">Consumer Units</a></li>
                            <li><a href="/search?cat=5">Fire Alarms</a></li>
                            <li><a href="/search?cat=6">Sockets</a></li>
                            <li><a href="/search?cat=7">Lights</a></li>
                            <li><a href="/search?cat=8">Switches</a></li>
                            <li><a href="/search?cat=9">Extractor Fans</a></li>
                            <li><a href="/search?cat=11">Electric Car Charging Point</a></li>
                            <li><a href="/search?cat=12">Connect Appliances</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="search-bar">
                <div class="search-container">
                    <form #formPesquisaTopo="ngForm" class="form-search-topo" novalidate (submit)="buscarFiltro()">
                        <button type="button" class="btn-search">
                            <span class="material-icons">
                                search
                            </span>
                        </button>
                        <input class="search" type="text" #campoPesquisa="ngModel" name="fieldPesquisa" [(ngModel)]="fieldPesquisa">
                    </form>
                </div>
            </div>
            <div>
                <div class="content-btn-cart mobile-whatsapp">
                    <button class="button-cart-topo btn-whatsapp btn-whatsapp-desktop" (click)="whatsapp()">
                        <i class="fab fa-whatsapp"></i>
                    </button>
                </div>
            </div>
            <div>
                <div class="content-btn-cart mobile-cart">
                    <a class="button-cart-topo" [routerLink]="['/cart']"><i class="material-icons">shopping_cart</i></a>
                    <span class="badge-cart">{{quantity}}</span>
                </div>
            </div>
        </div>
    </div>
</div>