<div id="spinner" *ngIf="spinnerShow">
    <mat-spinner></mat-spinner>
</div>
<div class="conteudo-site">
    <app-menu-topo></app-menu-topo>
    <app-menu-principal></app-menu-principal>
    <div class="container">
        <div class="row">
            <div class="content-login-form">
                <div class="col-md-12">
                    <form class="login-form" #formLogin="ngForm" novalidate>
                        <mat-form-field>
                            <mat-label>E-mail</mat-label>
                            <input matInput #campoEmail="ngModel" type="email" name="email" [(ngModel)]="email" ngModel
                                required minlength="5">
                        </mat-form-field>
                        <span class="error-input"
                            *ngIf="!campoEmail.valid && (campoEmail.dirty || campoEmail.touched)">Required!</span>

                        <mat-form-field>
                            <mat-label>Password</mat-label>
                            <input matInput #campoPassword="ngModel" type="password" name="password"
                                [(ngModel)]="password" ngModel required minlength="2" class="text-uppercase">
                        </mat-form-field>
                        <span class="error-input"
                            *ngIf="!campoPassword.valid && (campoPassword.dirty || campoPassword.touched)">Required!</span>

                    </form>
                    <button mat-raised-button color="primary" class="btn-form" (click)="doLogin()"
                        [disabled]="!formLogin.valid">Login</button>
                </div>
                <p><a href="/recoverpassword">Recover password</a></p>
                <p><a href="/cadastro">Sign up</a></p>
                <p><a href="/user-login/engineers/">Login as engineer</a></p>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>