import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  spinnerShow = false;

  urlBase = "https://phaseapp.uk/site/api_site/";
  urlImagens = "https://phaseapp.uk/site/imagens/";
  produtos;

  currentPage: number = 1;

  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.carregaDados();
  }

  qtdProdutos = 0;

  carregaDados() {
    this.spinnerShow = true;
    this.updateCart();

    this.http.get(this.urlBase + "getProdutos.php").subscribe(
      data => {
        this.produtos = data;
        this.spinnerShow = false;
      },
      error => {
        console.log(error);
        this.spinnerShow = false;
      }
    );
  }

  goToSingle(produto) {
    this.router.navigate(['/single'], { queryParams: { id: produto.idprodutos } });
  }

  updateCart() {
    let carrinho = JSON.parse(localStorage.getItem("phaseAppCart"));

    if (carrinho.services) {
      this.qtdProdutos = carrinho.services.length;
    }
  }

  addItemCart(produto: any) {
    let cart = JSON.parse(localStorage.getItem("phaseAppCart"));

    if (cart.services) {
      cart.services.push(produto);
      localStorage.setItem("phaseAppCart", JSON.stringify(cart));
    }

    else {
      let arr: Array<any> = [];
      arr.push(produto);
      cart.services=arr;
      localStorage.setItem("phaseAppCart", JSON.stringify(cart));
    }

    this._snackBar.open('Added to cart!', 'Ok', { duration: 3000 });

    this.updateCart();
  }

}
