import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DetailPedidoComponent } from '../detail-pedido/detail-pedido.component';

@Component({
  selector: 'app-minha-conta',
  templateUrl: './minha-conta.component.html',
  styleUrls: ['./minha-conta.component.css']
})
export class MinhaContaComponent implements OnInit {

  urlBase = "https://phaseapp.uk/site/api_site/";

  produtosDoPedido: Array<any> = [];
  qtdProdutos = 0;

  pedidosFeitos = null;

  spinnerShow = false;

  dadosLoginClientPhaseSite = null;
  
  constructor(
    private http: HttpClient,
    private _bottomSheet: MatBottomSheet,
  ) { }

  ngOnInit(): void {
    this.carregaDados();
  }

  carregaDados() {
    this.spinnerShow = true;
    this.updateCart();

    this.dadosLoginClientPhaseSite = JSON.parse(localStorage.getItem("dadosLoginClientPhaseSite"));
    this.http.get(this.urlBase + "filtroPedido.php?clientId=" + this.dadosLoginClientPhaseSite.idclientes).subscribe(
      data => {
        this.pedidosFeitos = data;
        console.log(this.pedidosFeitos);
        this.spinnerShow = false;
      },
      err => {
        console.log(err);
        this.spinnerShow = false;
      }
    );
  }

  detailPedido(idOrder) {
    this.produtosDoPedido = [];
    this.http.get(this.urlBase + "getPedidoFeito.php?order=" + idOrder).subscribe(
      data => {
        let retorno = data as any;
        this.produtosDoPedido = retorno;
      },
      err => {
        console.log(err);
      }
    );
  }

  updateCart() {
    let carrinho = JSON.parse(localStorage.getItem("phaseAppCart"));
    if (carrinho.services) {
      this.qtdProdutos = carrinho.services.length;
    }
  }

  nada(idOrder) {
    const bottomSheetRef = this._bottomSheet.open(DetailPedidoComponent, {
      data: { idOrder: idOrder },
    });
  }

}
