<div id="spinner" *ngIf="spinnerShow">
    <mat-spinner></mat-spinner>
</div>
<div class="conteudo-site">
    <app-menu-topo></app-menu-topo>
    <app-menu-principal></app-menu-principal>
    <div class="faixa-azul">
        <h5>Home > Search
            <span *ngIf="catNome"> > {{catNome}}</span>
        </h5>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <app-categorias></app-categorias>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col-md-12">
                        <pagination-controls class="pagination-products" (pageChange)="currentPage = $event">
                        </pagination-controls>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 20px;" *ngFor="let produto of produtos | paginate: { itemsPerPage: 8, currentPage: currentPage }">
                    <div class="col-md-4">
                        <div *ngIf="!produto.PROD_IMAGEM" (click)="goToSingle(produto)" class="img-cart-produto img-service-single" [ngStyle]="{'background-image' : 'url(assets/imagens/img-item-services.jpg)'}"></div>
                        <div *ngIf="produto.PROD_IMAGEM" (click)="goToSingle(produto)" class="img-cart-produto img-service-single" [ngStyle]="{'background-image' : 'url(' + urlImagens + produto.PROD_IMAGEM + ')'}"></div>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-12">
                                <h2 class="name-service-single" (click)="goToSingle(produto)">{{produto.PROD_NOME}}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="descricao-service">{{produto.PROD_DESCRIPTION}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="price-item-services">£{{produto.PROD_PRECO}} +VAT</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button class="btn-add-cart-services" (click)="addItemCart(produto.idprodutos)">
                                    <span class="material-icons">
                                        add_shopping_cart
                                    </span>
                                    Add To Cart
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <pagination-controls class="pagination-products" (pageChange)="currentPage = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>