import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  imagesBasic = [
    { img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(117).jpg', thumb:
    'https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(117).jpg', description: 'Image 1' }
    ];

  constructor() { }

  quantity = 0;

  ngOnInit(): void {
    this.updateCart();
  }

  updateCart() {
    let cart = JSON.parse(localStorage.getItem("phaseAppCart"));

    if (cart.services) {
      this.quantity = cart.services.length;
    }
  }

}
